import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import {Helmet} from 'react-helmet';
import ArticleGrid from '../components/ArticleGrid';
import Layout from '../components/layout';

const Blog = ({data}) => {
  return (
    <Layout>
      <Helmet>
        <title>Blog</title>
        <meta name="description" content="Black Cape Blog" />
      </Helmet>
      <section id="banner" className="blog">
        {data.file && (
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '600px',
              position: 'fixed',
              top: '0',
              zIndex: '0'
            }}
          />
        )}
        <div className="inner">
          <header className="major">
            <h1>Blog</h1>
          </header>
          <div className="content">
            <p>Thoughts on Technology from the Black Cape Staff</p>
          </div>
        </div>
      </section>
      <div id="main">
        <section id="one" style={{background: 'linear-gradient(180deg, rgba(0,0,0,0), #222)'}}>
          <div className="inner">
            <ArticleGrid articleType={'blog'} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: {eq: "blog.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Blog;
